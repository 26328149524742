
function FaqSection() {
    var faqList = [
        {
            'question': 'Q: 特定技能は何年間就労できますか',
            'ans': '特定技能1号の在留資格保持者の在留期間は、最長5年間です。その後、一定の条件をクリアすれば、特定技能2号となり、在留期限は5年ですが、5年を過ぎると更に延長することも可能です。'
        },
        {
            'question': 'Q: いつから「特定技能」で外国人を受入できますか',
            'ans': '業種によって条件がことなります。外国人が目的の業種での技能実習生での経験がない場合や、特別な資格が必要な場合などは、試験に合格する必要があります。試験の開始予定なども各業種ごとに違いますので、外国人受け入れ時期などにつきましてはお気軽に弊社にお問い合わせください。'
        },
        {
            'question': 'Q:「高度人材」は紹介してもらえますか？また何年間就労できますか？',
            'ans': '海外の有力なネットワークがありますので、高度人材のご紹介も可能です。また、特定活動（高度人材）のビザを持っている外国人の場合は最長5年となっていますが、就労3年以上の活動があった場合、高度専門職2号の就労資格に変更できれば、期間は無制限となります。 '
        },
        {
            'question': 'Q: 自社の業務が特定技能に該当する業務か知りたい',
            'ans': '現在対象とされているのは次の14業種「建設業」「造船・舶用工業」「自動車整備業」「航空業」「宿泊業」「介護」「ビルクリーニング」「農業」「漁業」「飲食料品製造業」「外食業」「素形材産業」「産業機械製造業」「電気電子情報関連産業」となります。'
        },
        {
            'question': 'Q: 英語ができなくても受け入れできますか？',
            'ans': 'ご紹介させていただく外国人スタッフの多くは、N4以上の日本語能力検定を取得していますので、日常的な日本語会話を聞きとれ、理解できるレベルですので、ゆっくりした簡単な日本語での会話は可能です。 '
        },
        {
            'question': 'Q: 外国人の受け入れは1名でも可能ですか？',
            'ans': '受け入れは1名から可能です。ただし、特定技能制度などの法令に基づき、受け入れる会社の規模により受入人数に制限がございます。'
        },
        {
            'question': 'Q: 日本人よりコストは安いですか？',
            'ans': ' 給与を安く採用できるということではございません。法令に準じた日本人と同等またはそれ以上の待遇でお考え下さい。※弊社からのご紹介では入社までにかかる費用は最小限の費用で設定しています。是非ご相談ください '
        },
    ]
    return (
        <div className="container w-full">
            <h2 data-aos="fade-up" data-aos-duration="800" className="text-center text-primary">よくあるご質問</h2>
            <div data-aos="fade-up" data-aos-duration="800" className="w-full join join-vertical">
                {faqList.map((data, index) => <div key={index} className="border collapse collapse-arrow join-item border-base-300">
                    <input type="radio" name='accordion' />
                    <div className="text-xl font-semibold collapse-title text-secondary">
                        {data.question}
                    </div>
                    <div className="collapse-content">
                        <p className="irs-secondary-text-color">{data.ans}</p>
                    </div>
                </div>)}
            </div>
            <div className="w-full mt-6 text-xl font-semibold text-center">
                <span>ご不明な点はお気軽にご連絡ください <a href="tel:0369102801" className='underline text-primary'>03-6910-2801 </a></span>
            </div>
        </div>
    );
}

export default FaqSection;