function IntroSection() {
    return (
        <div name="introSection" className="container w-full -mt-10 md:mt-auto">
            <h1 data-aos="zoom-in" data-aos-duration="1000" className="lg:hidden text-center mb-[3rem] drop-shadow-sm text-[1.8rem] md:text-[3rem] leading-[1.3] text-secondary ">自社運営の現地学校から<br/>ワンストップで人材をご案内いたします</h1>
            <div data-aos="fade-up" data-aos-duration="800" className="relative flex flex-col w-full gap-4 text-center text-white shadow-lg overflow-clip rounded-xl bg-slate-900">
                <video
                    autoPlay
                    loop
                    muted
                    className="absolute top-0 left-0 object-cover w-full h-full"
                >
                    <source src={process.env.PUBLIC_URL + '/japan-cross.mp4'} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <div className=" px-10 md:px-24 py-10 md:py-32 md:gap-4 flex flex-col z-[2] bg-black bg-opacity-20">
                    <h2 data-aos="fade-zoom-in" data-aos-duration="1000" data-aos-offset="200" className="mb-4 text-[1.5rem] md:text-5xl">特定技能・技能実習生</h2>
                    <p data-aos="fade-zoom-in" data-aos-duration="1500" data-aos-offset="210" className="text-lg text-white md:text-2xl">
                        日本で活躍できる人材を育成。働くのは日本国内です。日本基準をベースに教育し専門分野で即戦力で活躍できる【Japan Ready】を掲げ育成しています。
                    </p>
                </div>

            </div>
        </div>
    );
}

export default IntroSection;