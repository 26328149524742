import Lottie from "lottie-react";
import animationData from "../lottie/love.json";

function ContactMeModal({ handleModal }) {
  return (
    <>
      <div className="modal modal-middle modal-open">
        <div className="modal-box">
          <div className="flex flex-col items-center gap-4">
            <Lottie
              animationData={animationData}
              style={{ width: "300px", height: "300px", margin: "-80px" }}
              loop="false"
            />
            <div className="flex flex-col">
              <span className="mb-2 text-3xl font-bold text-center">ありがとう</span>
              <p className="text-center ">
                すぐにご連絡いたします
              </p>
            </div>

            <button
              type="button"
              className="w-full btn btn-primary irs-button"
              onClick={() => handleModal()}
            >
              大丈夫
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactMeModal;
