import RedirectSection from "../components/RedirectSection";
import HeroSection from "../components/HeroSection";
import Facilities from "../components/FacilitiesSection";
import IntroSection from "../components/IntroSection";
import FaqSection from "../components/FaqSection";
import ContactUs from "../components/ContactUsSection";
import StepSection from "../components/StepSection";
import OurServices from "../components/ServicesSection";
import CompanyAims from "../components/AimsSection";

function HomePage() {
    return (
        <div className="flex flex-col gap-24 md:gap-32">
            <HeroSection />
            <IntroSection />
            <Facilities />
            <RedirectSection />
            <StepSection />
            <OurServices />
            <CompanyAims />
            <FaqSection />
            <ContactUs />
        </div>
    );
}

export default HomePage;