import { useEffect, useRef, useState } from "react";
import emailjs from '@emailjs/browser';
import ContactMeModal from "./Modal";

function ContactUs() {
    const [Loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const formRef = useRef(null);

    //add public key
    useEffect(() => emailjs.init("EnOhDir5xvKrAALtC"), []);

    const handleModal = () => {
        setShowModal(!showModal);
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        const serviceId = "service_q5vg7xb";
        const templateId = "template_n4wyd96";
        try {
            setLoading(true);
            await emailjs.send(serviceId, templateId, {
                inquiry: formRef.current.radio_1.value,
                company_name: formRef.current.company_name.value,
                contact_info: formRef.current.contactInfo.value,
                from_name: formRef.current.charge_name.value,
                kana_name: formRef.current.kana_name.value,
                job_title_department_info: formRef.current.job_title_department.value,
                email: formRef.current.email.value,
                message: formRef.current.message.value
            });
            setShowModal(true);
            formRef.current.radio_1.value = '';
            formRef.current.company_name.value = '';
            formRef.current.charge_name.value = '';
            formRef.current.contactInfo.value = '';
            formRef.current.email.value = '';
            formRef.current.message.value = '';
            formRef.current.job_title_department.value = '';
            formRef.current.kana_name.value ='';
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };
    return (
        <>
            <div name="contactUsSection" className="container w-full ">
                <h2 data-aos="fade-up" data-aos-duration="800" className="text-center text-primary">お問い合わせ先</h2>
                <div data-aos="fade-up" data-aos-duration="800" className="flex flex-col w-full gap-14 lg:gap-4">
                    <div className="grid w-full grid-cols-1 gap-3 md:grid-cols-3 md:gap-0">
                        <h4 className=" text-secondary">電話でのお問い合わせ</h4>
                        <div className="col-span-2 ">
                            外国人雇用支援センター<br />
                            Office: <a href="tel:0369102801">03-6910-2801</a>
                        </div>
                    </div>
                    <div className="grid w-full grid-cols-1 gap-3 md:gap-0 md:grid-cols-3">
                        <h4 className="text-secondary">フォームから連絡</h4>
                        <div className="col-span-2 ">
                            <form className="flex flex-col md:gap-4" onSubmit={handleSubmit} ref={formRef}>
                                <div className="grid items-center grid-cols-1 gap-2 mb-4 md:grid-cols-4 md:gap-5">
                                    <label className="irs-secondary-text-color" htmlFor="radio_1">お問い合わせ項目<span className="text-error">✽</span></label>
                                    <div className="flex flex-wrap col-span-3 gap-4 text-sm">
                                        <div className="flex items-center gap-2">
                                            <input type="radio" name="radio_1" id="radio-1" className="radio radio-primary border-neutral" required value='詳しく聞きたい' />
                                            <label htmlFor="radio-1" className="text-[0.7rem] md:text-sm cursor-pointer">詳しく聞きたい</label>
                                        </div>
                                        <div className="flex items-center gap-2">
                                            <input type="radio" name="radio_1" id="radio-2" className="radio radio-primary border-neutral" required value='資料が欲しい' />
                                            <label htmlFor="radio-2" className="text-[0.7rem] md:text-sm cursor-pointer">資料が欲しい</label>
                                        </div>
                                        <div className="flex items-center gap-2">
                                            <input type="radio" name="radio_1" id="radio-3" className="radio radio-primary border-neutral" required value='その他' />
                                            <label htmlFor="radio-3" className="text-[0.7rem] md:text-sm cursor-pointer">その他</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid items-center grid-cols-1 gap-2 mb-4 md:grid-cols-4 md:gap-5">
                                    <label className="irs-secondary-text-color" htmlFor="company_name">御社名<span className="text-error">✽</span></label>
                                    <input type="text" id="company_name" name="company_name" className="w-full col-span-3 gap-4 border-gray-300 input input-bordered input-primary" required />
                                </div>
                                <div className="grid items-center grid-cols-1 gap-2 mb-4 md:grid-cols-4 md:gap-5">
                                    <label className="irs-secondary-text-color" htmlFor="charge_name">お名前<span className="text-error">✽</span></label>
                                    <input type="text" id="charge_name" name="charge_name" className="w-full col-span-3 gap-4 border-gray-300 input input-bordered input-primary" required />
                                </div>
                                <div className="grid items-center grid-cols-1 gap-2 mb-4 md:grid-cols-4 md:gap-5">
                                    <label className="irs-secondary-text-color" htmlFor="kana_name">ふりがな<span className="text-error">✽</span></label>
                                    <input type="text" id="kana_name" name="kana_name" className="w-full col-span-3 gap-4 border-gray-300 input input-bordered input-primary" required />
                                </div>
                                <div className="grid items-center grid-cols-1 gap-2 mb-4 md:grid-cols-4 md:gap-5">
                                    <label htmlFor="contactInfo" className="text-[0.9rem]">電話番号<span className="text-error">✽</span></label>
                                    <input type="tel" id="contactInfo" name="contactInfo" className="w-full col-span-3 gap-4 border-gray-300 input input-bordered input-primary" required />
                                </div>
                                <div className="grid items-center grid-cols-1 gap-2 mb-4 md:grid-cols-4 md:gap-5">
                                    <label className="irs-secondary-text-color" htmlFor="job_title_department" >部署・役職名<span className="text-error">✽</span></label>
                                    <textarea required id="job_title_department" name="job_title_department" className="w-full col-span-3 border-gray-300 textarea textarea-lg textarea-primary textarea-bordered" ></textarea>
                                </div>
                                <div className="grid items-center grid-cols-1 gap-2 mb-4 md:grid-cols-4 md:gap-5">
                                    <label className="irs-secondary-text-color" htmlFor="email" >メールアドレス<span className="text-error">✽</span></label>
                                    <input type="email" id="email" name="email" autoComplete="false" className="w-full col-span-3 gap-4 border-gray-300 input input-bordered input-primary" required />
                                </div>
                                <div className="grid items-center grid-cols-1 gap-2 mb-4 md:grid-cols-4 md:gap-5">
                                    <label className="irs-secondary-text-color" htmlFor="message" >お問い合わせ内容</label>
                                    <textarea id="message" name="message" className="w-full col-span-3 border-gray-300 textarea textarea-lg textarea-primary textarea-bordered" ></textarea>
                                </div>
                                <div>
                                    {Loading ?
                                        <button type="button" className=" btn btn-primary irs-button">
                                            <span className="loading loading-spinner"></span>  お待ちください
                                        </button>
                                        :
                                        <button className="btn btn-primary w-[8rem] irs-button">
                                            送信する <i className="text-lg fa-regular fa-paper-plane-top"></i>
                                        </button>}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </div>
            {showModal === true ? (
                <ContactMeModal handleModal={() => handleModal()} />
            ) : (
                ""
            )}

        </>
    );
}

export default ContactUs;