import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

function OurServices() {
    var serviceArray = [
        {
            image: '/images/services-1.JPG',
            header: 'J.TEC運営学校',
            desc: ' ミャンマー内に3つの直営校を運営両国の各機関、団体と連携を取りスムーズなマッチングと入国をお手伝いします。 '
        },
        {
            image: '/images/services-2.jpg',
            header: '疑似施設研修',
            desc: '職種に合わせ、実際に使用する器具や設備を配置し実務同様の研修を行っています。 '
        },
        {
            image: '/images/services-3.jpg',
            header: 'N4取得以上',
            desc: '来日する就労者は全員N4相当合格者です。本校学生の会話スキルは、N3相当の日常的な日本語会話を聞きとれ、理解できるレベルです。'
        },
        {
            image: '/images/services-4.jpg',
            header: '高い審査基準',
            desc: '現地国内での家族を含めた犯罪歴や本人の病歴等を連携機関と調査し、基準をクリアした人材だけを出国させています。'
        }
    ];

    return (
        <div name="serviceSection" className="container w-full">
            <h2 data-aos="fade-up" data-aos-duration="800" className="text-center text-primary">当社のサービス</h2>
            <div data-aos="fade-up" data-aos-duration="800" className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4">
                {serviceArray.map((data, index) => (
                    <div key={index} className="card w-full bg-base-100 border-[1px] hover:shadow-md transition-all duration-300 overflow-hidden rounded-lg">
                        <Zoom>
                            <img src={data.image} alt={data.header} className="h-[12rem] object-cover w-full" />
                        </Zoom>

                        <div className="p-5 card-body">
                            <h4 className="text-lg card-title text-secondary">{data.header}</h4>
                            <p className="text-sm irs-secondary-text-color">{data.desc}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>

    );
}

export default OurServices;