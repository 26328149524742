function CompanyAims() {
    return (
        <div className="container w-full">
            <h2 className="text-center text-primary">一般社団法人 国際人材就職支援機構</h2>
            <div className="flex flex-col gap-4 ml-4 md:text-xl">
                <p className="font-semibold">ごあいさつ</p>
                <ul className="list-[square] flex-col flex gap-4">
                    <li>
                        パンドラの箱は開け放たれた
                        超少子高齢化の到来により、わが国は労働人口の減少に直面しています。政府は海外労働者の導入に
                        慎重に取り組んできましたが、この度労働者需給調整の観点から入管法を改定し、在留資格の拡大・
                        延長に大きく方針転換しました。
                    </li>
                    <li>
                        日本の働き手クライシスが始まった
                        民間の労働人口推移調査によれば、2023年から17年後の2040年までには1,100万人もの減少が
                        予測されており、これはわが国にとって働き手確保の重大な局面や危機状況を示唆しています。
                    </li>
                    <li>
                        日本向けの人材育成に特化した訓練校
                        私たちキャリアテックジャパンは、設立以来アジア諸国から優秀な人材を募り日本向けの人材育成を行ってきました。
                        特定技能法案が成立したことを受けて、ミャンマー国ヤンゴンに日本向けのブランド人材を育成する
                        ためのJapan Ready Functions Training Schoolを立ち上げました。この学校は独自の評価基準に
                        基づいており、採用企業から高い評価をいただいています。
                        私たちはこれからも、海外人材活用におけるさまざまな課題を解決し、国際人材交流の新たな時代を
                        切り拓いていく所存です。
                    </li>
                </ul>
            </div>

        </div>
    );
}

export default CompanyAims;