import { FcFeedback, FcIphone } from "react-icons/fc";

function Footer() {
    return (
        <>
            <div className="w-full mt-20 bg-slate-50">
                <div className="container w-full">
                    <div className="grid grid-cols-1 gap-6 py-16 lg:grid-cols-3 md:p-10">
                        <div className='flex h-fit'>
                            <img src="/images/jtec-logo.png" alt="Jtec" className='w-[10rem] md:w-[15rem]' />
                        </div>
                        <div>
                            <div className="flex flex-col justify-center w-full gap-6">
                                <div className="flex flex-col">
                                    <h5 className="mb-2 lg:mb-3 text-secondary"> 一般社団法人 国際人材就職支援機構 </h5>
                                    <div className='flex flex-col gap-2'>
                                        <div className="flex items-center gap-2">
                                            <FcIphone className='text-2xl' />
                                            <a href="tel:0369102801" className=' hover:underline hover:text-primary'>03-6910-2801 </a>
                                        </div>
                                        <div className="flex items-center gap-2">
                                            <FcFeedback className='text-2xl' />
                                            <a className=' hover:underline hover:text-primary' href="mailto:news@irs-recruit.org">news@irs-recruit.org</a>
                                        </div>
                                    </div>
                                </div>
                                {/* <div>
                                    <h5 className="mb-2 lg:mb-3 text-secondary">Follow Us On</h5>
                                    <div className='flex gap-4'>
                                        <a href="#?">
                                            <i className="text-2xl text-blue-600 fa-brands fa-facebook"></i>
                                        </a>
                                        <a href="#?">
                                            <i className="text-2xl fa-brands fa-x-twitter text-slate-900"></i>
                                        </a>
                                        <a href="#?">
                                            <i className="text-2xl text-blue-500 fa-brands fa-linkedin"></i>
                                        </a>
                                        <a href="#?">
                                            <i className="text-2xl text-pink-600 fa-brands fa-instagram"></i>
                                        </a>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div>
                            <div className="flex flex-col justify-center w-full ">
                                <h5 className='mb-2 text-secondary lg:mb-3'>住所</h5>
                                <small className="mb-3">〒105-0003東京都港区虎ノ門1丁目11の14第２ジェスペール4階</small>
                                <iframe className="rounded-md h-[12rem]" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d25929.508097029662!2d139.761759!3d35.67236!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188b92f6d11ee3%3A0xd43d835b5a060f33!2z5Lqs5paZ55CGIOOBqOadkSBUb211cmE!5e0!3m2!1sen!2sus!4v1699073191022!5m2!1sen!2sus" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" title="JTEC"></iframe>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <footer className="p-4 text-white footer footer-center bg-primary">
                <aside>
                    <p>Copyright © 2023 - All right reserved by J.TEC Co, Ltd</p>
                </aside>
            </footer>
        </>

    );
}

export default Footer;