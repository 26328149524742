import { Link as ScrollLink } from 'react-scroll';

function RedirectSection() {
    return (
        <div className="container w-full ">
            <div data-aos="fade-up" data-aos-duration="800" className="relative flex flex-col w-full gap-4 text-center text-white shadow-lg overflow-clip rounded-xl bg-slate-900">
                <img src={process.env.PUBLIC_URL + '/stars.jpg'} alt="landscape" className="absolute top-0 left-0 object-cover w-full h-full " />
                <div className=" z-[2] gap-4 flex flex-col bg-black bg-opacity-30 p-10 px-8 md:p-16">
                    <h2 className="mb-2 text-[1.7rem] lg:text-4xl">是非、比べてください。余分な費用はいただきません。</h2>
                    <p className="text-lg lg:text-xl text-slate-400">
                        対応業種、様々なご事例ございます。はじめてご検討される方もお気軽にご相談ください。
                    </p>
                    <div className="flex justify-center w-full">
                        <ScrollLink
                            to="contactUsSection"
                            spy={true}
                            smooth={true}
                            offset={-100}
                            duration={1000}
                            className='btn btn-primary irs-button w-fit'
                        >
                            お問い合わせはこちら
                        </ScrollLink>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RedirectSection;