import { Link as ScrollLink } from 'react-scroll';

function HeroSection() {
    return <div name="heroSection" className="w-full flex justify-center items-center h-[25vh] lg:h-[100vh] hero-bg-image">
        <div className="flex-col items-center justify-end hidden w-full h-full gap-6 p-4 mt-[10rem] bg-black bg-opacity-0 lg:flex lg:justify-center">
            <h1 data-aos="zoom-in" data-aos-duration="1000" className="text-center text-base-100 drop-shadow-md text-[2rem] md:text-[3rem] leading-[1.3] bg-secondary rounded-md p-2 bg-opacity-60">
            自社運営の現地学校から<br/>ワンストップで人材をご案内いたします
            </h1>
            <div data-aos="zoom-in" data-aos-duration="1500" >
                <ScrollLink
                    to="introSection"
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={1000}
                    className="gap-2 border-2 rounded-sm irs-button btn lg:btn-lg px-7 lg:px-14">Get Started
                </ScrollLink>
            </div>

        </div>
    </div>;
}

export default HeroSection;