import { RiGovernmentLine } from "react-icons/ri";
import { LiaFileContractSolid, LiaIdCardSolid, LiaToriiGateSolid } from "react-icons/lia";
import { PiCertificateDuotone, PiHandshakeDuotone } from 'react-icons/pi';

function StepSection() {
    var sampleArray = [
        {
            icon: <PiHandshakeDuotone className=" text-2xl lg:text-[2.5rem]" />,
            header: '採用確定',
            desc: '内定後、日本大使館指定病院で詳細な健康診断を実施します。',
            left: true,
        },
        {
            icon: <LiaFileContractSolid className="text-2xl lg:text-[2.5rem]" />,
            header: '雇用契約締結',
            desc: '本人・受入機関双方の同意内容に基づいて雇用契約を締結します。',
            left: true,
        },
        {
            icon: <PiCertificateDuotone className="text-2xl lg:text-[2.5rem]" />,
            header: '在留資格認証証明交付申請 ',
            desc: '日本の出入国在留管理局へ在留資格認定証明書（COE）交付申請を提出します。',
            left: false,
        },
        {
            icon: <LiaIdCardSolid className="text-2xl lg:text-[2.5rem]" />,
            header: '海外労働身分証明カード',
            desc: 'COE受領後、ミャンマー労働省に海外労働身分証明カード（スマートカード）発行申請を行います。',
            left: false,
        },
        {
            icon: <RiGovernmentLine className="text-2xl lg:text-[2.5rem]" />,
            header: '査証申請',
            desc: '在外公館にて査証の申請を行います。',
            left: true,
        },
        {
            icon: <LiaToriiGateSolid className="text-2xl lg:text-[2.5rem]" />,
            header: '日本へ出国',
            desc: 'スマートカードと査証が交付されると日本への出国が可能になります。',
            left: true,
        }
    ];

    return (
        <div className="container flex flex-col overflow-clip">
            <h2 className="justify-center text-center text-primary">採用から入国までの流れ</h2>
            <div className="w-full">
                <div className="relative flex flex-col items-start justify-center gap-6 md:items-center lg:gap-4">
                    {sampleArray.map((data, index) => (
                        <div key={index} className="w-full grid grid-cols-1 md:grid-cols-2 gap-[4.5rem]">
                            <div className={`w-full flex pl-14 md:pl-0  ${data.left ? 'col-start-1 justify-end' : 'col-start-1 md:col-start-2'}`}>
                                <div className="relative flex items-center w-full md:w-auto">
                                    <div className={`absolute ${data.left ? '-left-14 md:left-auto md:-right-14' : '-left-14'} bg-primary w-10 h-10 flex items-center justify-center text-white font-bold rounded-full z-20`}>{index + 1}</div>
                                    <div className="hidden md:block">
                                        <div data-aos={data.left ? 'fade-left' : 'fade-right'} data-aos-offset="250" className="relative flex items-center p-4 border-[1px] w-fit bg-slate-100 rounded-xl">
                                            <div className={`flex flex-col gap-4 items-center ${data.left ? 'lg:flex-row-reverse' : 'lg:flex-row'}`}>
                                                <div className="border-[3px] p-3 lg:p-4 rounded-full border-primary bg-red-50 text-primary-focus">
                                                    {data.icon}
                                                </div>
                                                <div className={`text-center flex flex-col gap-1 ${data.left ? 'lg:text-right' : 'lg:text-left'}`}>
                                                    {/* <span className="text-sm text-success">Step {index + 1}</span> */}
                                                    <h4 className="text-sm lg:text-xl" >{data.header}</h4>
                                                    <p className="text-xs lg:text-[1rem] irs-secondary-text-color">{data.desc}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full md:hidden">
                                        <div data-aos='fade-left' data-aos-offset="250" className="relative flex items-center p-4 border-[1px] w-full bg-slate-100 rounded-xl">
                                            <div className={`flex w-full flex-col gap-4 items-center ${data.left ? 'lg:flex-row-reverse' : 'lg:flex-row'}`}>
                                                <div className="border-[3px] p-3 lg:p-4 rounded-full border-primary bg-red-50 text-primary-focus">
                                                    {data.icon}
                                                </div>
                                                <div className={`text-center flex flex-col gap-1 ${data.left ? 'lg:text-right' : 'lg:text-left'}`}>
                                                    {/* <span className="text-sm text-success">Step {index + 1}</span> */}
                                                    <h4 className="text-sm lg:text-xl" >{data.header}</h4>
                                                    <p className="text-xs lg:text-[1rem] irs-secondary-text-color">{data.desc}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    <div className="absolute h-[85%] lg:h-[90%] border-2 border-secondary rounded-full md:ml-0 ml-[1.15rem]"></div>
                </div>
            </div>
        </div>

    );
}

export default StepSection;