function Facilities() {
    return (
        <div name="facilitiesSection" className="container w-full">
            <h2 data-aos="fade-up" data-aos-duration="800" className="text-center text-primary">他社にない3つの強み</h2>
            <div data-aos="fade-up" data-aos-duration="800" className="grid grid-cols-1 md:grid-cols-3">
                <div className="flex flex-col gap-4  border-[1px] p-5">
                    <h3 className="text-[1.1rem] text-center text-secondary whitespace-nowrap">①紹介料０円＋ローコスト</h3>

                    <div className="w-full mt-0 overflow-hidden rounded-md aspect-video md:mt-8 lg:mt-0">
                        <img src="/images/facilities-1.jpg" alt="building" className="object-cover object-center w-full h-full aspect-video" />
                    </div>
                    <div className="flex flex-col gap-4">
                        <p className="irs-secondary-text-color"> 弊社はミャンマーに現地学校を運営しています。ミャンマー政府をはじめ優良な管理団体との連携して送り出しから入国、管理までを一貫して行っています。

                            仲介業者を介入させない仕組みの為、紹介料などは発生しません。 </p>
                        <ul className="flex flex-col gap-2 pl-4 list-disc irs-secondary-text-color">
                            <li>明瞭かつ優良な料金体系を提示
                                中間業者は介入せずワンストップ型にすることでトラブルのない仕組みを実現しています。</li>
                            <li>必要最低限の手続き費用、管理費用で国内へ召喚。生活、住居サポートを行い国内人材と変わらぬ、またはそれ以上の定着率を実現します。 </li>
                        </ul>
                    </div>
                </div>
                <div className="flex flex-col gap-4 border-x-[1px] md:border-x-0  md:border-y-[1px] p-5">
                    <h3 className=" text-[1.1rem] text-center text-secondary">②安定した日本語能力<br className="lg:hidden" />（JLPT N4等取得済）</h3>
                    <div className="w-full overflow-hidden rounded-md aspect-video">
                        <img src="/images/facilities-2.jpg" alt="building" className="object-cover object-center w-full h-full aspect-video" />
                    </div>
                    <div className="flex flex-col gap-4">
                        <p className="irs-secondary-text-color">自社運営の訓練施設とライセンス制度で安定した能力水準を確保しています。</p>


                        <ul className="flex flex-col gap-2 pl-4 list-disc irs-secondary-text-color">
                            <li>J.TECライセンス制度（合格ライン）を設けることにより能力水準の安定を図り、学生のモチベーション向上に努めています。</li>
                            <li>受け入れ先企業が安心して人選できるというメリットがあります。 </li>
                            <li>特定技能14業種に対応した訓練が可能介護は提携病院で実務訓練や夜間訓練を実施。各種の座学やOJTを実施</li>
                        </ul>
                    </div>
                </div>
                <div className="flex flex-col gap-4  border-[1px] p-5">
                    <h3 className="text-[1.1rem] text-center text-secondary whitespace-nowrap">③WEBキャリアセンター</h3>
                    <div className="w-full mt-0 overflow-hidden rounded-md aspect-video md:mt-8 lg:mt-0">
                        <img src="/images/facilities-3.png" alt="building" className="object-cover object-center w-full h-full aspect-video" />
                    </div>
                    <div className="flex flex-col gap-4">
                        <p className="irs-secondary-text-color">J.TECでは技能、職能別に登録している人材をリアルタイムに検索及び確認ができます。

                            さらに求める人材をオーダーいただくことで条件に見合った人材を手配することが可能です。 </p>
                        <ul className="flex flex-col gap-2 pl-4 list-disc irs-secondary-text-color">
                            <li>WEB面接で効率的な採用活動に
                                現地に出向いての人選や面接もございますがWEB面接により効率かつスピーディーに採用を進めることができます。 </li>
                            <li>完全無料のオペレーションシステムで登録後はでいつでも面接、面談が可能です。</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Facilities;