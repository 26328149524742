import { useEffect, useState } from 'react';
import { Link as ScrollLink } from 'react-scroll';
import $ from 'jquery';
import { HiOutlineBars3CenterLeft } from "react-icons/hi2";

function NavBar() {
    const [scrolling, setScrolling] = useState(false);
    const [sideBarToggle, setsideBarToggle] = useState(false);

    useEffect(() => {
        if (window.scrollY > 0) {
            setScrolling(true);
        } else {
            setScrolling(false);
        }
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setScrolling(true);
            } else {
                setScrolling(false);
            }
        };

        // Attach the event listener when the component mounts
        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (sideBarToggle) {
            $('body').addClass('overflow-hidden');
            $('#sidebar').addClass('right-0').removeClass('-right-[50rem]');
            $('#sidebar-backdrop').removeClass('hidden');
        } else {
            $('body').removeClass('overflow-hidden');
            $('#sidebar').addClass('-right-[50rem]').removeClass('right-0');
            $('#sidebar-backdrop').addClass('hidden');

        }
    }, [sideBarToggle]);

    const navbarClasses = `w-full fixed top-0 z-50 transition-all ${scrolling ? 'bg-white shadow-sm' : 'bg-white'
        }`;
    return (
        <>
            <div className={navbarClasses}>
                <div className="w-full lg:container ">
                    <div className="navbar">
                        <div className="lg:hidden navbar-start">

                        </div>
                        <div className="navbar-center lg:navbar-start">
                            <ScrollLink
                                to="heroSection"
                                spy={true}
                                smooth={true}
                                offset={-100}
                                duration={1000}
                                className='btn btn-ghost hover:bg-transparent'>
                                <img src="/images/jtec-logo.png" alt="Jtec" className='w-[8rem]' />
                            </ScrollLink>
                        </div>
                        {/* <div className="navbar-center">

                    </div> */}
                        <div className="hidden gap-4 navbar-end lg:flex">
                            <ul className="gap-2 px-1 menu menu-horizontal ">
                                <li>
                                    <ScrollLink
                                        activeClass="active"
                                        to="heroSection"
                                        spy={true}
                                        smooth={true}
                                        offset={-100}
                                        duration={1000}
                                    >
                                        ホームページ
                                    </ScrollLink>
                                </li>
                                <li>
                                    <ScrollLink
                                        activeClass="active"
                                        to="facilitiesSection"
                                        spy={true}
                                        smooth={true}
                                        offset={-100}
                                        duration={1000}
                                    >
                                        施設
                                    </ScrollLink>
                                </li>
                                <li>
                                    <ScrollLink
                                        activeClass="active"
                                        to="serviceSection"
                                        spy={true}
                                        smooth={true}
                                        offset={-100}
                                        duration={1000}
                                    >
                                        当社のサービス
                                    </ScrollLink>
                                </li>
                            </ul>
                            <ScrollLink
                                to="contactUsSection"
                                spy={true}
                                smooth={true}
                                offset={-100}
                                duration={1000}
                                className='btn btn-primary irs-button'
                            >
                                お問い合わせ
                            </ScrollLink>
                        </div>
                        <div className="lg:hidden navbar-end">
                            <button onClick={() => setsideBarToggle(true)} className='p-0 btn btn-circle btn-ghost'>
                                <HiOutlineBars3CenterLeft className='text-2xl transform flip-horizontal' />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div id='sidebar-backdrop' onClick={() => setsideBarToggle(false)} className="hidden fixed top-0 left-0 h-[100vh] w-full bg-black bg-opacity-40 z-50 cursor-pointer transition-all duration-500"></div>
            <div id='sidebar' className='fixed top-0 -right-[50rem] bg-white w-[18rem] h-[100vh] z-[60] transition-all duration-300'>
                <div className='flex justify-center py-10 pt-12'>
                    <img src="/images/jtec-logo.png" alt="Jtec" className='w-[10rem] lg:w-[15rem]' />
                </div>
                <ul className="flex flex-col w-full gap-1 menu">
                    <li>
                        <ScrollLink
                            onClick={() => setsideBarToggle(false)}
                            activeClass="active"
                            to="heroSection"
                            spy={true}
                            smooth={true}
                            offset={-100}
                            duration={1000}
                        >


                            ホームページ
                        </ScrollLink>
                    </li>
                    <li>
                        <ScrollLink
                            onClick={() => setsideBarToggle(false)}
                            activeClass="active"
                            to="facilitiesSection"
                            spy={true}
                            smooth={true}
                            offset={-100}
                            duration={1000}
                        >



                            施設
                        </ScrollLink>
                    </li>
                    <li>
                        <ScrollLink
                            onClick={() => setsideBarToggle(false)}
                            activeClass="active"
                            to="serviceSection"
                            spy={true}
                            smooth={true}
                            offset={-100}
                            duration={1000}
                        >

                            当社のサービス
                        </ScrollLink>
                    </li>
                    <li>
                        <ScrollLink
                            onClick={() => setsideBarToggle(false)}
                            activeClass="active"
                            to="contactUsSection"
                            spy={true}
                            smooth={true}
                            offset={-100}
                            duration={1000}
                        >

                            お問い合わせ
                        </ScrollLink>
                    </li>
                </ul>
                <button onClick={() => setsideBarToggle(false)} className="absolute btn btn-ghost btn-circle top-1 right-1">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
            </div>
        </>
    );
}

export default NavBar;