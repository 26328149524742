import { Outlet } from "react-router-dom";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import ScrollToTopButton from "./components/ScrollToTopButton";

function MainLayout() {
  useEffect(() => {
    AOS.init({
      once: true,
    });
    AOS.refresh();
  }, []);
  return (
    <>
      <NavBar />
      <Outlet />
      <Footer />
      <ScrollToTopButton />
    </>
  );
}

export default MainLayout;
