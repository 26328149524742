import { BrowserRouter, Route, Routes } from 'react-router-dom';
import PageNotFound404 from './lib/pages/PageNotFound';
import HomePage from './lib/pages/HomePage';
import MainLayout from './lib/layout';

function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route index element={<HomePage />} />
        </Route>
        <Route path="*" element={<PageNotFound404 />} />
      </Routes>
    </BrowserRouter>

  );
}

export default App;
